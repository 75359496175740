import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RatingBarComponent } from './rating-bar.component';
import {MatIconModule} from "@angular/material/icon";
import {FlexModule} from "@angular/flex-layout";



@NgModule({
  declarations: [
    RatingBarComponent
  ],
    imports: [
        CommonModule,
        MatIconModule,
        FlexModule
    ],
  exports: [ RatingBarComponent ]
})
export class RatingBarModule { }
