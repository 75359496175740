import {Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {fuseAnimations} from "@fuse/animations";
import {TenantUserService} from "../../../../../core/services/tenantuser.service";
import {AppRatingService} from "../app-rating.service";
import {AppRatingDlgComponent} from "../app-rating-dlg.component";
import {MatDialog} from "@angular/material/dialog";


@Component({
    selector: "app-rating-section",
    templateUrl: "./app-rating-section.component.html",
    styleUrls: ["./app-rating-section.component.scss"],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})

export class AppRatingSectionComponent implements OnInit {
    constructor(
        private appRatingService: AppRatingService,
        private dialog: MatDialog
    ) {
    }

    showRatingUi: boolean;
    reviewId: string;
    googleReviewLink: string;
    currentRating: number;

    ngOnInit(): void {
        this.isShowRatingsToStudent();
    }

    isShowRatingsToStudent() {
        this.appRatingService.isStudentReviewedApp().subscribe((res: any) => {
            if (!res.result.status && res.result.isGoogleReviewLinkPresent) {
                this.showRatingUi = true;
            }else{
                this.showRatingUi = false;
            }
        }, (error) => {
            console.log('error occured posting review', error);
        });
    }

    ratingBarClicked(val) {
        this.currentRating = val;
        let payload = {
            stars: val,
            reviewText: "Rating submitted"
        };
        this.appRatingService.postReview(payload).subscribe((response: any) => {
            this.reviewId = response.reviewResult._id;
            if (response.reviewResult.isGoogleLinkGenerated) {
                this.openGoogleReview();
            } else {
                    const dialogRef = this.dialog.open(AppRatingDlgComponent, {
                        disableClose: true,
                        width: '500px',
                        data: {currentRating: val, reviewId: this.reviewId },
                        backdropClass: 'backdropBackground',
                    });
                    dialogRef.afterClosed().subscribe(result => {
                        this.isShowRatingsToStudent();
                    });
            }
        }, (error) => {
            console.log('error occured posting review', error);
        });
    }
    openGoogleReview() {
        let payload = {
            reviewText: 'user redirected to leave a google review',
            isReviewedOnGoogle: true,
            stars: this.currentRating
        };
        this.appRatingService.updateReview(payload, this.reviewId).subscribe((response: any) => {
            this.isShowRatingsToStudent();
            this.googleReviewLink = response.reviewResult.googleReviewLink;
            window.open(this.googleReviewLink, '_blank');
        }, (error) => {
        });
    }
}
