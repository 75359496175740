import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AppRatingDlgComponent } from "./app-rating-dlg.component";
import { AppRatingService } from "./app-rating.service";
import { MatDialogModule } from "@angular/material/dialog";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatDividerModule } from "@angular/material/divider";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import {FlexLayoutModule} from "@angular/flex-layout";
import {RatingBarModule} from "../rating-bar/rating-bar.module";
import {MatStepperModule} from "@angular/material/stepper";
import {MatTooltipModule} from "@angular/material/tooltip";
import {ClipboardModule} from "@angular/cdk/clipboard";
import {AppRatingSectionComponent} from "./app-rating-section/app-rating-section.component";

@NgModule({
  declarations: [AppRatingDlgComponent, AppRatingSectionComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        MatToolbarModule,
        MatDividerModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        FormsModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatRadioModule,
        MatProgressSpinnerModule,
        FlexLayoutModule,
        RatingBarModule,
        MatStepperModule,
        MatTooltipModule,
        ClipboardModule,
    ],
  exports: [AppRatingDlgComponent, AppRatingSectionComponent],
  providers: [AppRatingService]
})
export class AppRatingDlgModule {}
