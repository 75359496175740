<div style="width: 100%;margin-bottom: 5px;position: relative;">
    <button mat-dialog-close aria-label="Close dialog" fxLayout="row" fxLayoutAlign="end end"
            style="background: #ffffff; color: #000">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div>
    <mat-horizontal-stepper #stepper>
            <mat-step>
                <p style="text-align: center;">We’d like to improve your experience.</p>

                <h2 class="feedback-message-title">Please share your thoughts</h2>
                <form [formGroup]="reviewForm" class="review-form" >
                    <app-rating-bar [maxRating]="5" [currentRating]="currentRating"
                                    (ratingChange)="onRatingChange($event)"></app-rating-bar>
                    <div class="form-group review-text-sec">
<!--                        <label for="review" style="margin-bottom: 3px;">Your Review</label>-->
                        <textarea
                                id="review"
                                formControlName="review"
                                rows="4"
                                class="form-control"
                                placeholder="Write your review here..."
                        ></textarea>
                        <div *ngIf="ratingRequiredError" class="text-danger">
                            Ratings is required.
                        </div>
                        <div *ngIf="feedbackTextRequiredError" class="text-danger">
                            Review is required and must be at least 10 characters long.
                        </div>
                    </div>

                    <button type="submit" class="btn btn-primary" (click)="feedbackSubmit()">Submit Review</button>
                </form>
            </mat-step>
            <mat-step>
                <div>
                    <h2 class="feedback-message-title">Thank you for your review!</h2>
                    <p> Your feedback helps us improve and provide a better experience. We appreciate your support!</p>
                </div>
            </mat-step>
        <!-- Do you mind posting on Google Section -->
        <mat-step>
            <div>
                <h2 class="feedback-message-title">Thank you for your review!</h2>
                <p> Your feedback helps us improve and provide a better experience. Would you like to post it on google?</p>
                <button type="submit" class="btn btn-primary" (click)="goToGoogleReviewPage()">Submit Review to Google</button>
            </div>
        </mat-step>
    </mat-horizontal-stepper>

</div>

