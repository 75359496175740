import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "environments/environment";
@Injectable({
  providedIn: "root"
})
export class AppRatingService {
  constructor(private http: HttpClient) {}

  postReview( payload: any) {
    let apiUrl = `${environment.apiBaseUrl}/providerservice/review`;
    return this.http.post(apiUrl, payload);
  }
  updateReview( payload: any, id: any) {
    let apiUrl = `${environment.apiBaseUrl}/providerservice/review/${id}`;
    return this.http.put(apiUrl, payload);
  }
  isStudentReviewedApp(){
    const url = `${environment.apiBaseUrl}/providerservice/review/mstatus`;
    return this.http.get(url);
  }
}
