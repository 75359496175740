import {Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {fuseAnimations} from "@fuse/animations";
import {MatStepper} from "@angular/material/stepper";
import {AppRatingService} from "./app-rating.service";
import {Clipboard} from "@angular/cdk/clipboard";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "app-rating-dlg",
    templateUrl: "./app-rating-dlg.component.html",
    styleUrls: ["./app-rating-dlg.component.scss"],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})

export class AppRatingDlgComponent implements OnInit {
    constructor(private fb: FormBuilder,
                private appRatingService: AppRatingService,
                private toastr: ToastrService,
                public dialogRef: MatDialogRef<AppRatingDlgComponent>, 
                @Inject(MAT_DIALOG_DATA) public data: { currentRating: number, reviewId: string }) {
    }

    @ViewChild('stepper', {static: false}) stepper!: MatStepper;

    reviewForm: FormGroup;
    ratingRequiredError: boolean = false;
    feedbackTextRequiredError: boolean = false;
    currentRating: number;
    googleReviewLink: string;
    reviewId: string;


    ngOnInit(): void {
        this.currentRating = this.data.currentRating;
        this.reviewId = this.data.reviewId;
        this.reviewForm = this.fb.group({
            review: ['', [Validators.required, Validators.minLength(10)]],
        });
    }

    onRatingChange(rateVal) {
        this.ratingRequiredError = false;
        this.currentRating = rateVal;
    }

    feedbackSubmit(): void {
        if (this.currentRating === undefined) {
            this.ratingRequiredError = true;
        } else if (this.reviewForm.invalid) {
            this.feedbackTextRequiredError = true;
        } else {
            // submit form
            let payload = {
                stars: this.currentRating,
                reviewText: this.reviewForm.get('review').value
            };
            this.appRatingService.updateReview(payload, this.reviewId).subscribe((response: any) => {
                this.reviewId = response.reviewResult._id;
                this.googleReviewLink = response.reviewResult.googleReviewLink;
                if (response.reviewResult.isGoogleLinkGenerated){
                    this.stepper.selectedIndex = 2;
                }else{
                    this.stepper.selectedIndex = 1;
                }
            }, (error) => {
            });
        }
    }
    goToGoogleReviewPage(){
        this.openGoogleReview();
    }
    openGoogleReview() {
        let payload = {
            reviewText: 'user redirected to leave a google review',
            isReviewedOnGoogle: true,
            stars: this.currentRating
        };
        this.appRatingService.updateReview(payload, this.reviewId).subscribe((response: any) => {
            this.googleReviewLink = response.reviewResult.googleReviewLink;
            window.open(this.googleReviewLink, '_blank');
            this.dialogRef.close();
        }, (error) => {
            this.toastr.error('Error occurred while submitting review for Google:', error);
        });
    }
}
