<ng-container *ngIf="variant === 'horizontal-style-1'">
    <navbar-horizontal-style-1></navbar-horizontal-style-1>
    <app-rating-section *ngIf="isStudent()"></app-rating-section>
</ng-container>

<ng-container *ngIf="variant === 'vertical-style-1'">
    <navbar-vertical-style-1></navbar-vertical-style-1>
</ng-container>

<ng-container *ngIf="variant === 'vertical-style-2'">
    <navbar-vertical-style-2></navbar-vertical-style-2>
</ng-container>
