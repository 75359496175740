import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppLoadService } from "app/core/services/appload.service";
import { AppConstants } from "app/core/settings/appconstants";
import { environment } from "environments/environment";
import {Observable} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ExternalWebsiteService {

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private http: HttpClient, private _appLoadService: AppLoadService) {
    // Set the defaults
  }
  addInternalWebsitePage(pageName, form: any) {
    this.setWebsiteRefresh();
    return this.http.post(`${environment.apiBaseUrl}/stemplate/webpage/${pageName}`, form);
  }
  updateInternalWebsitePage(pageId, form: any) {
    this.setWebsiteRefresh();
    return this.http.put(`${environment.apiBaseUrl}/stemplate/webpage/${pageId}`, form);
  }
  deleteWebsitePage(pageId) {
    this.setWebsiteRefresh();
    return this.http.delete(`${environment.apiBaseUrl}/stemplate/webpage/${pageId}`);
  }
  getInternalWebsitePage(pageName) {
    return this.http.get(`${environment.apiBaseUrl}/stemplate/webpage/${pageName}`);
  }
  getAvailableWebsitePages() {
    return this.http.get(`${environment.apiBaseUrl}/stemplate/webpage`);
  }
  getInternalWebsitePublicPage(pageName, tenantId) {
    return this.http.get(`${environment.apiBaseUrl}/stemplate/public/web/${tenantId}/${pageName}`);
  }
  updateInternalWebsitePageSequence( payload: any) {
    return this.http.put(`${environment.apiBaseUrl}/stemplate/webpage/all/sequence`, payload);
  }
  getTemplateListByPageType(pageType, pageIndex, max): Observable<any> {
    return this.http.get(
        `${environment.apiBaseUrl}/stemplate/wtemplate?pageType=${pageType}&start=${pageIndex}&max=${max}`
    );
  }
  addWebsiteTemplate(form: any) {
    this.setWebsiteRefresh();
    let url = `${environment.apiBaseUrl}/stemplate/wtemplate`;
    return this.http.post(url, form);
  }
  deleteUntemplate(id: any): Observable<any> {
    this.setWebsiteRefresh();
    return this.http.delete(`${environment.apiBaseUrl}/stemplate/wtemplate/${id}`);
  }
  
  private setWebsiteRefresh() {
    localStorage.setItem(AppConstants.WEBSITE_REFRESHED, 'true');
  }

}

