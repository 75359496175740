import { Component, EventEmitter, OnInit, OnDestroy, ViewEncapsulation, PLATFORM_ID, Inject } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { AppConstants } from 'app/core/settings/appconstants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppLoadService } from 'app/core/services/appload.service';
import { TenantUserService } from 'app/core/services/tenantuser.service';
import { AccountDetail } from '../models/account-details';
import { AppSettingsService } from '../services/app-settings.service';
import { isPlatformBrowser } from '@angular/common';
import { SubSink } from 'subsink';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FileObject } from 'app/main/shared/components/file-upload/file-upload.component';
import { ProductService } from '../../products/services/product.service';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-website-setting',
  templateUrl: './website-setting.component.html',
  styleUrls: ['./website-setting.component.scss'],
  animations: fuseAnimations,
})
export class WebsiteSettingComponent {
  settingsList: any[];
  form: FormGroup;
  _accountDetail: any;
  subs = new SubSink();
  websiteLogo: any;
  orgId: any;
  scaleLogo: any;
  isLoading: boolean = false;
  headerColor = '';
  favoriteIcon: any;
  favImageUrl: string;
  favImage: { id: number; url: string }[] = [];
  favImage$: EventEmitter<FileObject[]> = new EventEmitter<FileObject[]>();

  bannerImageUrl: string;
  seoDescription: string;
  title: string;
  bannerImage: { id: number; url: string }[] = [];
  bannerImage$: EventEmitter<FileObject[]> = new EventEmitter<FileObject[]>();

  constructor(
    private _router: Router,
    private _appLoadService: AppLoadService,
    private _formBuilder: FormBuilder,
    private _tenantUserService: TenantUserService,
    private _settingsService: AppSettingsService,
    private SpinnerService: NgxSpinnerService,
    public toastr: ToastrService,
    private _productsService: ProductService,
  ) {
    this.settingsList = [
      {
        title: 'External Website',
        description: 'Integrate your existing website',
        url: 'externalwebsite',
        icon: 'open_in_new',
      },
      {
        title: 'SEO settings',
        description: 'Add keywords you want people to see on google search results',
        url: 'seosetting',
        icon: 'search',
      },
    ];
  }

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      favIcon: [],
      logo: [],
      pageScript: [],
      seoDescription: [],
      themeColor: [],
      twitter: [],
      facebook: [],
      instagram: [],
      linkedin: [],
      logoHeight: [],
      logoWidth: [],
      isShowFooter: [],
      privacyPolicyLink: [],
      tosLink: [],
      // isScaleLogo: [],
      footerInfo: [],
      title: [],
      page1: [],
      page2: [],
      page3: [],
      page4: [],
      page5: [],
      headerColor: [this.headerColor],
      googleReviewLink: ''
    });

    this.isLoading = true;
    this.SpinnerService.show();
    this.subs.add(
      this._settingsService.getPluginIntTenant().subscribe(
        (details: any) => {
          this._accountDetail = details;
          this.headerColor = details.headerColor;
          this.bindFormData();
          this.SpinnerService.hide();
          this.isLoading = false;
        },
        error => {
          this.SpinnerService.hide();
          this.isLoading = false;
        },
      ),
    );
    this.orgId = this._appLoadService.getOrgId();
    this.initBannerImage();
    this.initFavIconImage();
    this.orgId = this._appLoadService.getOrgId();
  }

  headerColorChanged($event): void {
    this.headerColor = $event;
    this.bindFormData();
  }

  initBannerImage(): void {
    this.websiteLogo = '';
    this.subs.add(
      this.bannerImage$.subscribe(res => {
        this.bannerImage = res;
        const list = this.bannerImage[0].url.split('/');
        const length = list.length;
        this.bannerImageUrl = `${list[length - 2]}/${list[length - 1]}`;
        this.postLogo(this.bannerImageUrl);
      }),
    );
  }


  // showOptions(event): void {
  //   this.scaleLogo = event.checked;
  //   this._accountDetail.isScaleLogo;
  // }

  initFavIconImage(): void {
    this.subs.add(
      this.favImage$.subscribe(res => {
        this.favImage = res;
        const list = this.favImage[0].url.split('/');
        const length = list.length;
        this.favImageUrl = `${list[length - 2]}/${list[length - 1]}`;
        this.postFavIcon(this.favImageUrl);
      }),
    );
  }

  postFavIcon(url: any): void {
    let list = url.split('/');
    let length = list.length;
    let favIconUrl = `${list[length - 2]}/${list[length - 1]}`;
    this.favoriteIcon = '';
    this.form.controls.favIcon.setValue(favIconUrl);
  }

  postLogo(url: any): void {
    let list = url.split('/');
    let length = list.length;
    let logoUrl = `${list[length - 2]}/${list[length - 1]}`;
    this.websiteLogo = '';
    this.form.controls.logo.setValue(logoUrl);
  }

  navigateTo(url) {
    this._router.navigate([AppConstants.APP_SETTINGS_ROUTE, url]);
  }

  bindFormData(): void {
    // Reactive Form
    this.form = this._formBuilder.group({
      favIcon: [this._accountDetail.favIcon],
      logo: [this._accountDetail.logo],
      pageScript: [this._accountDetail.pageScript],
      seoDescription: [this._accountDetail.seoDescription],
      themeColor: [this._accountDetail.themeColor],
      title: [this._accountDetail.title],
      twitter: [this._accountDetail.twitter],
      facebook: [this._accountDetail.facebook],
      instagram: [this._accountDetail.instagram],
      linkedin: [this._accountDetail.linkedin],
      footerInfo: [this._accountDetail.footerInfo],
      isShowFooter: [this._accountDetail.isShowFooter],
      privacyPolicyLink: [this._accountDetail.privacyPolicyLink],
      tosLink: [this._accountDetail.tosLink],
      // isScaleLogo: [this._accountDetail.isScaleLogo],
      logoHeight: [this._accountDetail.logoHeight],
      logoWidth: [this._accountDetail.logoWidth],
      // page1: [this._accountDetail.page1],
      // page2: [this._accountDetail.page2],
      // page3: [this._accountDetail.page3],
      // page4: [this._accountDetail.page4],
      // page5: [this._accountDetail.page5],
      headerColor: [this.headerColor],
      googleReviewLink: [this._accountDetail.googleReviewLink],
    });

    if (this._accountDetail.favIcon) {
      this.subs.add(
        this._productsService
          .getcloudinaryBaseURL()
          .pipe(take(1))
          .subscribe(resp => {
            let baseURL = resp.cloudinaryBaseURL;
            this.favImage.push({
              id: 1,
              url: `${baseURL}${this._accountDetail.favIcon}`,
            });
            this.favoriteIcon = this.favImage[0].url;
          }),
      );
    }

    if (this._accountDetail.logo) {
      this.subs.add(
        this._productsService
          .getcloudinaryBaseURL()
          .pipe(take(1))
          .subscribe(resp => {
            let baseURL = resp.cloudinaryBaseURL;
            this.bannerImage.push({
              id: 1,
              url: `${baseURL}${this._accountDetail.logo}`,
            });
            this.websiteLogo = this.bannerImage[0].url;
          }),
      );
    }
  }

  public setFooterMessage(event){
    this.form.get('footerInfo').setValue(event.html);
  }

  updateSettings(): void {
    if (this.form.valid) {
      this.isLoading = true;
      this.subs.add(
        this._settingsService.updatePluginTenant(this.form.value).subscribe(
          next => {
            this.isLoading = false;
            this._appLoadService.updateTenantConfig(true);
            this.toastr.success('Successfully updated settings.');
            // window.location.href = window.location.origin + "/settings";
          },
          error => {
            this.toastr.error(error);
            this.isLoading = false;
          },
        ),
      );
    } else {
      this.toastr.error('Invalid Form!');
    }
  }
}
