import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-rating-bar',
  templateUrl: './rating-bar.component.html',
  styleUrls: ['./rating-bar.component.scss']
})
export class RatingBarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  @Input() maxRating = 5;

  // Current rating value
  @Input() currentRating = 0;
  @Input()   starSize = 40;

  // Output event to notify parent of rating change
  @Output() ratingChange = new EventEmitter<number>();

  // Method to update the rating
  rate (star: number) {
    this.currentRating = star;
    this.ratingChange.emit(this.currentRating);
  }
}
